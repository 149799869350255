<template>
  <div>
    <template
      v-for="(id) in loadingGraphs">
      <div v-if="loading(id)">Loading...</div>
      <iframe
        :key="id"
        :src="`https://public.flourish.studio/visualisation/${id}/embed`"
        @load="loadNext(id)"
        title="flourish"
        width="700"
        height="575"
        style="width:100%;border:0px solid rgb(221,221,221);"></iframe>
    </template>
  </div>
</template>
<script>
export default {
  name: 'prices-graphs',
  props: {
    graphIds: {
      type: Array,
      default: () => ([]),
    },
    render: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loadingGraphs: [],
      loadedGraphs: [],
    };
  },
  watch: {
    render(shouldRender) {
      if (shouldRender) {
        this.loadGraph(this.loadGraph(this.graphIds[0]));
      }
    }
  },
  mounted() {
    if (this.render) {
      this.loadGraph(this.graphIds[0]);
    }
  },
  methods: {
    loading(id) {
      return this.graphIds.length > this.loadedGraphs.length &&
        !this.loadedGraphs.includes(id);
    },
    loadGraph(id) {
      if (id) {
        this.loadingGraphs.push(id);
      }
    },
    loadNext(loadedGraphId) {
      this.loadedGraphs.push(loadedGraphId);
      if (this.graphIds.length > this.loadedGraphs.length) {
        this.loadGraph(this.graphIds[this.loadedGraphs.length]);
      }
    },
  }
};
</script>
