import { cacheControlMerger } from './cacheControl/_defaultCacheControl';
import { isEditionPreview } from 'global-utils';

function getContextAwareCacheContol(req, key) {
  return (
    req &&
    req.publication &&
    req.publication.cacheControlMap &&
    req.publication.cacheControlMap[key]
  ) || {};
}

export async function initCacheControl(response = {}) {
  response.cacheControl = cacheControlMerger();
}

export function setCacheControlExpressRoutes(routeKey, req, response = {}) {
  response.cacheControl = cacheControlMerger(getContextAwareCacheContol(req, routeKey));
}

export function setCacheControlNewsletterRoutes(routeKey, req, response = {}) {
  response.cacheControl = cacheControlMerger(getContextAwareCacheContol(req, routeKey));
}

export function setCacheControlVueRoutes(options = {}, req, response = {}) {
  const {
    pageType,
    errorCode,
    reqPath,
  } = options;
  if (isEditionPreview(req.query)) {
    /**
     * setting the cacheControl value for preview edition page the same as articlePreviewPage
     */
    response.cacheControl = cacheControlMerger(getContextAwareCacheContol(req, 'articlePreviewPage'));
    return;
  }
  if (pageType === 'error') {
    response.cacheControl = cacheControlMerger(getContextAwareCacheContol(req, `${pageType}${errorCode}`));
  } else {
    const pageTypeCacheControl = getContextAwareCacheContol(req, pageType);
    response.cacheControl = cacheControlMerger((pageTypeCacheControl[reqPath] || pageTypeCacheControl));
  }
}
