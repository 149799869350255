import { resolvePidsFromCategories } from './resolvePids';
const defaultPid = 'EPNEWS';
const categoriesEPVISIO = [
  'editions',
];
const categoriesEPNEWS = [
  'kraftmarked',
  'utdanning',
  'bredbånd',
  'elektrifisering',
  'kronikk',
  'varme_og_kjøling',
  'økonomi',
  'arbeidsliv',
  'nytt_om_navn',
  'nett',
  'vindkraft',
  'teknologi',
  'forbruker',
  'struktur',
  'nyheter',
  'solenergi',
  'politikk',
  'vannkraft',
  'produksjon',
  'debatt',
  'bransjestruktur',
];
const categoriesEPMAG = [
  'energi_distrikt',
  'teknikk',
  'fjernvarme',
  'i_bransjen',
  'småkraft',
  'energi_utdanning',
  'aktuelt',
  'meninger',
  'marked',
  'vår_energi',
  'leder',
  'magasin',
];

function populateXPidMap() {
  let pidsMap = categoriesEPNEWS.reduce((merged, item) => {
    merged[item] = 'EPNEWS';
    return merged;
  }, {});
  pidsMap = categoriesEPMAG.reduce((merged, item) => {
    merged[item] = 'EPMAG';
    return merged;
  }, pidsMap);
  pidsMap = categoriesEPVISIO.reduce((merged, item) => {
    merged[item] = 'EPVISIO';
    return merged;
  }, pidsMap);
  return pidsMap;
}
const pidsMap = populateXPidMap();

export default (categories) => {
  const xPids = resolvePidsFromCategories(categories, defaultPid, pidsMap);
  return {
    xPids: { newContent: xPids, oldContent: xPids },
    xAllowRefererAccess: false
  };
};
