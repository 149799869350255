<template>
  <div class="wrapper">
    <Row>
      <Column
        xs="12"
        md="3">
        <h2
          class="ff-flama fs-xxl fw-light tt-uppercase mb-5 position-sticky"
          style="top:65px">
          {{ __('pricesPageTitle') }}
        </h2>
      </Column>
      <Column
        xs="12"
        md="9">
        <LazyHydrate
          v-if="skipRender"
          never>
          <div>
            <div class="global-article-selector">
            </div>
          </div>
        </LazyHydrate>
        <div
          v-else
          class="content">
          <div class="d-none d-lg-block btn-group btn-group-toggle mb-1 prices-nav">
            <RouterLink
              v-for="label in fishes"
              :key="label"
              :to="`?fish=${label}`"
              :class="['btn btn-primary tt-uppercase', activeTab === label && 'active']">
              {{ label }}
            </RouterLink>
          </div>
          <div class="d-lg-none d-block prices-nav">
            <select
              @change="changeTab($event)"
              class="tt-uppercase w-100 p-2 mb-1"
              style="border-color: rgb(221,221,221)">
              <option
                v-for="label in fishes"
                :key="label"
                :value="label"
                :selected="activeTab === label">
                {{ label.toUpperCase() }}
              </option>
            </select>
          </div>
          <PricesGraphs
            v-for="([fish, graphIds]) in Object.entries($pubCtx.fishes || {})"
            v-show="fish === activeTab"
            :key="fish"
            :graph-ids="graphIds"
            :render="fish === activeTab"/>
        </div>
      </Column>
      <Column
        v-show="skipRender"
        xs="12"
        md="12">
        <div
          id="sub-paywall-container"
          class="sub-paywall-container">
        </div>
      </Column>
    </Row>
  </div>
</template>
<script>

import LazyHydrate from 'vue-lazy-hydration';
import { Page } from 'global-components';
import PricesGraphs from './PricesGraphs';
import { getPaywallMeta } from '../../../../server/core/paywallMetadataProcessor';

const buildPropMeta = (property, content, idx) => ({
  property,
  content,
  idx,
});

export default {
  name: 'price-page',
  components: {
    LazyHydrate,
    PricesGraphs,
  },
  extends: Page,
  data() {
    const fishes = Object.keys(this.$pubCtx.fishes || {});
    return {
      fishes,
      activeTab: this.$route.query.fish || fishes[0],
      skipRender: true,
    };
  },
  watch: {
    $route() {
      this.activeTab = this.$route.query.fish;
    },
  },
  mounted() {
    // window.subPaywallShow gets injected by Zephr if we want to show paywall
    // The code can be found in the component library of Zephr
    this.skipRender = !!window.subPaywallShow;
    this.updatePaywall();
    console.log(`#GLOBAL-ARTICLE(PRICES):: skipRender= "${this.skipRender}", zephrObject= "${JSON.stringify(window.Zephr)}"`);
  },
  methods: {
    changeTab(event) {
      this.$router.push({ query: { fish: event.target.value } });
    },
    async updatePaywall() {
      while (!window.subPaywallHtml) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      document.querySelector('#sub-paywall-container').innerHTML = window.subPaywallHtml;
    },
  },
  embed(head) {
    const addMeta = head.meta.bind(head);
    const paywallMeta = getPaywallMeta(this.$store.state);
    addMeta(buildPropMeta('paywall:is_paid', paywallMeta.isPaid));
    paywallMeta.pids.split(',').map((pid, idx) => addMeta(buildPropMeta('paywall:pids', pid.trim(), idx)));
  }
};
</script>
