const _cacheControl = {
  maxAge: 5,
  sMaxAge: 1800,
  noStore: false,
  noCache: false,
  mustRevalidate: false,
  proxyRevalidate: false
};

export function cacheControlMerger(cacheControl = {}) {
  return { ..._cacheControl, ...cacheControl };
}
