import { getXPidParams } from 'global-response-header';
import editionConfig from 'global-configs/edition';

const dayInMilis = 86400000;
const calculateArticleAge = publishedAt => {
  const publishedDate = new Date(publishedAt).valueOf();
  return Number.isNaN(publishedDate) ? 0 : parseInt((new Date().valueOf() - publishedDate) / dayInMilis);
};

export function getPaywallMeta(state = {}) {
  const result = { isPaid: false, pids: '' };

  if (state.article && state.article.id) {
    const isNewContent = calculateArticleAge(state.article.publishedAt) <= 14;
    const xPidParams = getXPidParams(state.publication.code, state.article.categories);
    result.pids = xPidParams.xPids[isNewContent ? 'newContent' : 'oldContent'];

    result.isPaid = state.route.meta.pageContext.isPaid && state.article.paid && (state.route.meta.pageContext.type !== 'articlePreviewPage');
    result.isReferrerAccess = (state.article.meta.referrerAccess === 'true');
  } else if (state.editions) {
    const categories = [{ label: 'Epaper Edition', url: '/editions' }];
    const xPidParams = getXPidParams(state.publication.code, categories);
    result.pids = xPidParams.xPids.newContent;

    const isPaid = state.route.meta.pageContext.isPaid || false;
    const isEPaperEditionPaid = state.publication.isEPaperEditionPaid || false;
    const hideEditionsPagePaywall = editionConfig[state.publication.code].hideEditionsPagePaywall || false;
    result.isPaid = isPaid && isEPaperEditionPaid && !hideEditionsPagePaywall;
  } else {
    const xPidParams = getXPidParams(state.publication.code);
    result.pids = xPidParams.xPids.newContent;

    result.isPaid = state.route.meta.pageContext.isPaid || false;
  }

  result.isPaid = (!state.publication.isFreePublication) && result.isPaid;

  return result;
}
