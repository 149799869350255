import { _isNil } from 'global-utils';

export const resolvePidsFromCategories = (categories, defaultPid, pidsMap) => {
  const _xpidSet = new Set(defaultPid ? [defaultPid] : []);
  if (!_isNil(categories)) {
    Object.keys(pidsMap).forEach(prop => {
      categories.reduce((cum, category) => {
        if (category.url === `/${prop}`) {
          _xpidSet.add(pidsMap[prop]);
        }
      },
      {});
    });
  }
  return [..._xpidSet].sort().join(',');
};
