import { resolvePidsFromCategories } from './resolvePids';
const defaultPid = 'IFCOFA';
const pidsMap = { aquaculture: 'IFCOAQUA', fisheries: 'IFCOFISH', company_profiles: 'IFNOFA' };

export default (categories) => {
  const xPids = resolvePidsFromCategories(categories, defaultPid, pidsMap);
  return {
    xPids: { newContent: xPids, oldContent: xPids },
    xAllowRefererAccess: false
  };
};
